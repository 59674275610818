import React from 'react'
import styled from 'styled-components'
import { Container, } from 'reactstrap';
import Client1 from '../../images/clientImg/client1.png'
import Client2 from '../../images/clientImg/client2.png'
import Client3 from '../../images/clientImg/client3.png'
import Client5 from '../../images/clientImg/client5.png'
import Client6 from '../../images/clientImg/client6.png'
import Client13 from '../../images/clientImg/client13.png'
import Client14 from '../../images/clientImg/client14.png'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Zoom from 'react-reveal/Zoom';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';


const Clients = () => {
    var client = {
        dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        // arrows: true,
        prevArrow: <FaChevronLeft color="#5d6d84" to="prev" />,
        nextArrow: <FaChevronRight color="#5d6d84" to="next" />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <section className="client_section_bg">
            <Zoom bottom>
                <Container>
                    <div className="section_heading">
                        <h3>We connect great talent to great companies</h3>
                    </div>
                    <Slider {...client}>
                        <div>
                            <div className="client_section">
                                <div className="client_icon">
                                    <img src={Client1} alt="img" />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="client_section">
                                <div className="client_icon">
                                    <img src={Client2} alt="img" />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="client_section">
                                <div className="client_icon">
                                    <img src={Client3} alt="img" />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="client_section">
                                <div className="client_icon">
                                    <img src={Client5} alt="img" />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="client_section">
                                <div className="client_icon">
                                    <img src={Client6} alt="img" />
                                </div>
                            </div>
                        </div>
                        {/* <div>
                            <div className="client_section">
                                <div className="client_icon">
                                    <img src={Client7} alt="img" />
                                </div>
                            </div>
                        </div> */}
                        {/* <div>
                            <div className="client_section">
                                <div className="client_icon">
                                    <img src={Client8} />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="client_section">
                                <div className="client_icon">
                                    <img src={Client9} />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="client_section">
                                <div className="client_icon">
                                    <img src={Client10} />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="client_section">
                                <div className="client_icon">
                                    <img src={Client11} />
                                </div>
                            </div>
                        </div> */}
                        {/* <div>
                            <div className="client_section">
                                <div className="client_icon">
                                    <img src={Client12} alt="img" />
                                </div>
                            </div>
                        </div> */}
                        <div>
                            <div className="client_section">
                                <div className="client_icon">
                                    <img src={Client13} alt="img" />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="client_section">
                                <div className="client_icon">
                                    <img src={Client14} alt="img" />
                                </div>
                            </div>
                        </div>
                    </Slider>
                </Container>
            </Zoom>
        </section>
    )
}
const StyledBackgroundSection = styled(Clients)`
width: 100%;
background-position: bottom center;
background-repeat: repeat-y;
background-size: cover;
margin: 0;
background-color: inherit !important;

`

export default StyledBackgroundSection
