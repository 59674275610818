import React from 'react'
import styled from 'styled-components'
import { Container, Row, Col, } from 'reactstrap'
import Img from "gatsby-image"

// import Zoom from 'react-reveal/Zoom';
// import { Container, Row, Col, } from 'reactstrap'
import Zoom from 'react-reveal/Fade';
import Banner from '../../images/banner.jpg'
import { graphql, StaticQuery } from 'gatsby'



const Banner1 = (props) => (
    <StaticQuery
        query={graphql`
  query {
    Banner: file(relativePath: { eq: "banner.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1220, maxHeight:480,) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      } 
    
  }
`}
        render={data => {
            const imageData = data.Banner.childImageSharp.fluid
            return (
                <section className="banner_min">
                    <div className="banner_section">
                        <Img
                            fluid={imageData}
                            className="startup_background"
                        ></Img>
                    </div>
                    <div className="banner_bg">
                        <div className="main_first_banner">
                            <Container>
                                <Row>
                                    <Col sm="12">
                                        <div className="first_banner_text">
                                            <Zoom  bottom delay={1000}><h1>Tough Requirements?</h1></Zoom>
                                            <Zoom  bottom delay={1000}><a  onClick={props.showModal}>Try us</a></Zoom>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </div>
                </section>


            )
        }}
    />
)
const StyledBackgroundSection = styled(Banner1)`
width: 100%;
background-position: bottom center;
background-repeat: repeat-y;
background-size: cover;
margin: 0;
background-color: inherit !important;

`

export default StyledBackgroundSection