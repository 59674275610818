import React, { useState } from 'react'
import Banner from '../components/Banner'
import AboutSection from '../components/AboutSection'
import Clients from '../components/Clients'
import Testimonial from '../components/Testimonial'
import Approach from '../components/Approach'
import { Container, Row, Col, Modal, ModalBody,} from 'reactstrap';
import Areas from '../components/AreasExpertise'
import BlogForm from './Company/Blog/BlogForm'

const Home = (props) => {
    const {
        className
    } = props;

    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);
    // const formReference = React.createRef();

    return (
        <div>
            <Banner showModal={toggle}/>
            {/* <Header/> */}
            <section className="startup_bg">
                <div className="startup_section">
                    <Container>
                        <Row>
                            <Col m="12">
                                <div className="text_center">
                                    <p>Trident Consulting is  a certified 100% woman- and minority-owned staffing company, incorporated in 2005. Some of our achievements include making it to the Inc. 5000 list and Bay Area's list of Top 100 fastest growing companies.
                                        {/* Trident Consulting was incorporated in 2005 and is a 100% Women owned and Minority owned staffing company, headquartered in San Ramon, California with sales offices in Texas and New Jersey. Trident specializes in placing high-quality vetted Technology and Engineering professionals in contract and full time roles. */}
                                            <br />
                                        {/* Trident’s offshore offices are located in India and up and coming in the Philippines. We can with pride say that the key to our success is the unparalleled performance of our delivery team, made up of over 70 Senior IT Recruiters. */}
                                        </p>
                                    {/* <p>Trident Consulting was incorporated in 2005 and is a 100% Women owned and Minority owned staffing company, headquartered in San Ramon, California with sales offices in Texas and New Jersey. We specialize in placing high-quality vetted Technology and Engineering professionals in contract and full time roles.
                                           Trident’s offshore offices are located in India and up and coming in the Philippines. We can with pride say that the key to our success is the unparalleled performance of our delivery team, made up of over 70 Senior IT Recruiters.
                                        </p> */}
                                    {/* <p>Trident Consulting was incorporated in 2015 and is a 100% Women owned and Minority owned staffing company, headquartered in San Ramon, CA with sales offices in Texas and NJ. We specialize in placing high-quality vetted Technology and Engineering professionals in contract and full time roles.</p>
                                        <p>Trident shines in filling niche/hard to find Tech skills, and our commitment each time is to deliver the best  and brightest in the industry for the toughest requirements. Give us a chance, and you’ll not be disappointed. </p>
                                        <p>We are big on Diversity. We truly believe the U.S. is a land of opportunities and equality, and are committed to helping people of diversity find equal opportunities.
                                            We’ve placed amazing talent at large financial services firms—positions that remained unfilled for months! We’ve beaten out shiny large tech companies in the Valley to convince candidates to work for smaller—and more promising—start ups. Trident’s offshore offices are located in India and up and coming in the Philippines. We can with pride say that the key to our success is the  unparalleled performance of our delivery team, made up of over 70 Senior IT Recruiters
                                        </p> */}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </section>
            <AboutSection />
            <Clients />
            <Testimonial />
            <Areas />
            <Approach />
            <section className="footer_section">
                <Container>
                    <Row>
                        <Col md="12">
                            <h1>Find out what Trident can do for you.</h1>
                            <div className="footer_button">
                                <a onClick={toggle} href>Contact Us</a>
                            </div>
                            <Modal isOpen={modal} toggle={toggle} className={className} className="form_modal">
                                {/* <ModalHeader toggle={toggle}>Modal title</ModalHeader> */}
                                <ModalBody >
                                    <BlogForm title="Find out what Trident can do for you." close={()=> toggle()}/>
                                </ModalBody>
                                {/* <ModalFooter>
                                    <Button color="primary" onClick={toggle}>Do Something</Button>{' '}
                                    <Button color="secondary" onClick={toggle}>Cancel</Button>
                                </ModalFooter> */}
                            </Modal>
                        </Col>
                    </Row>
                </Container>

            </section>
        </div>
    )
    // }
}
export default Home;