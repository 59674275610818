import React, { Component } from 'react'
import { Container, Row, Col } from 'reactstrap';
import Job from '../../images/svg/job.svg'
import Puzzle from '../../images/svg/puzzle.svg'
import Matches from '../../images/svg/matches.svg'
import Offers from '../../images/svg/offers.svg'



export default class Approach extends Component {
    render() {
        return (
            <div className="startup_section">
                <Container>
                <div className="section_heading">
                    <h3 className="customers_heading">Our approach</h3>
                    {/* <p>We take the time to understand your needs and identify, engage and close quality candidates.</p> */}
                </div>
               <Row>
                   <Col md="3">
                       <div className="approach">
                           <img src={Job} width={100} alt="img"/>
                           <h4>Understand Your Job</h4>
                       </div>
                   </Col>
                   <Col md="3">
                       <div className="approach">
                           <img src={Puzzle} width={100} alt="img" />
                           <h4>Identify Candidates</h4>
                       </div>
                   </Col>
                   <Col md="3">
                       <div className="approach">
                           <img src={Matches} width={100} alt="img" />
                           <h4>Engage Matches</h4>
                       </div>
                   </Col>
                   <Col md="3">
                       <div className="approach">
                           <img src={Offers} width={100} alt="img" />
                           <h4>Close Offers</h4>
                       </div>
                   </Col>
               </Row>
               </Container>  
            </div>
        )
    }
}
