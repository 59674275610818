import React from "react";
import { Container } from 'reactstrap';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import styled from 'styled-components'
// import { graphql, StaticQuery } from 'gatsby';
// import User6 from '../../images/cert/c4.png'
// import Slide from 'react-reveal/Slide';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

const Testimonial = () => {
  var testimonials = {
    dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        // arrows: true,
        prevArrow: <FaChevronLeft color="#5d6d84" to="prev" />,
        nextArrow: <FaChevronRight color="#5d6d84" to="next" />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
};
  return (
    <div className="testimonail_area">
          <Container>
            <div className="section_heading">
              <h3 className="customers_heading">What our customers say</h3>
            </div>
            <Slider {...testimonials}>
              <div>
                <div className="customers_box">
                  <p>“Trident was able to find us the right candidates, they prioritized getting to know us as a business and the skills we were looking for in Engineering. Trident has always been able to deliver high quality candidates in a timely cost efficient manner. They have always been an excellent resource for us, and I would recommend them to any company."</p>
                  <div className="customers_name">
                    <strong>- Adam Bundy</strong>
                  </div>
                </div>
              </div>
              <div>
                <div className="customers_box">
                  <p>"Trident has been in past my 'go-to' recruitment partner for technology roles, and I used to get excited when one of their resumes landed in my in-box. They were my top source of on target candidates, especially for niche roles, and and I would certainly recommend them for recruitment partnership without hesitation"</p>
                  <div className="customers_name">
                    <strong>- Holly Arkwright</strong>
                  </div>
                </div>
              </div>
              <div>
                <div className="customers_box">
                  <p>What has really impressed me about Trident  is their level of partnership. They’re very responsive to feedback and consistently deliver amazing  candidates. They kept plugging away to find us the perfect fit.”</p>
                  <div className="customers_name">
                    <strong>- Rachelle Salem</strong>
                  </div>
                </div>
              </div>
              <div>
                <div className="customers_box">
                  <p>Working with Trident and their staff is always a pleasure. Trident fully understands the varying business needs of the many industries in our unique and diverse county, always providing excellent customer service to all. They were my go to vendor for all our staffing needs.</p>
                  <div className="customers_name">
                    <strong>- Nitish Anthony</strong>
                  </div>
                </div>
              </div>
              <div>
                <div className="customers_box">
                  <p>"I have worked with Trident for the last four years now, and find them quick to respond, easy to deal with, and transparent in their HR dealings. They do a thorough job of finding the perfect fit for your skill sets, and this is true of the even specialized requirements. Service with a smile and I would recommend Trident to anyone looking for quality IT services."</p>
                  <div className="customers_name">
                    <strong>- Manish Tejura</strong>
                  </div>
                </div>
              </div>
              <div>
                <div className="customers_box">
                  <p>I have worked with Trident Consulting for 4 years and have always found them to be professional and consistent. They are responsive and quick on the uptake, which means I know that my requirements are met without unnecessary delays. This is especially true with our niche requests – they manage to come up with the right matches time after time!</p>
                  <div className="customers_name">
                    <strong>- Amit Guha</strong>
                  </div>
                </div>
              </div>
            </Slider>
          </Container>
        </div>

  )
}

export default Testimonial
