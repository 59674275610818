import React from 'react'
import styled from 'styled-components'
import { Container, Row, Col, } from 'reactstrap';
// import { GiCrystalShine, GiMissileLauncher, } from 'react-icons/gi';
// import { FaRegStar } from 'react-icons/fa';
// import { IoMdGlobe } from 'react-icons/io';
// import AboutImg from '../../images/about.jpg'
import Zoom from 'react-reveal/Zoom'
import Img1 from '../../images/svg/about1.svg'
import Img2 from '../../images/svg/about2.svg'
import Img3 from '../../images/svg/about3.svg'
import Video from '../../images/ceo/video_banner.jpg'
import Fast100 from '../../images/ceo/fast100.jpg'
import { FaLinkedin } from 'react-icons/fa';
import YouTube from 'react-youtube';




const AboutSection = () => {
    return (
        <section>
            <div className="about_section">
                <Zoom>
                    <Container>
                        <Row>
                            <Col sm="12" md={{ size: 2, offset: 1 }}>
                                <div className="about_svg">
                                    <img src={Img1} color="#5d6d83" alt="img" />
                                </div>
                            </Col>
                            <Col md="8">
                                <div className="about_text">
                                    <p><strong> Trident shines </strong>in finding hard-to-fill resources for your toughest requirement. Our team of 80 recruiters along with latest technology enabled ATS makes it possible to deliver on this.</p>
                                    {/* <p>Trident shines in finding hard-to-fill resources for your toughest requirements. Give us a chance, and you’ll not be disappointed.</p> */}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="12" md={{ size: 2, offset: 1 }}>
                                <div className="about_svg">
                                    <img src={Img2} color="#5d6d83" alt="img" />
                                </div>
                            </Col>
                            <Col md="8">
                                <div className="about_text">
                                    <p><strong>Finding Diverse Talent </strong> We have helped clients fulfill their demand for diverse talent by finding professionals that meet criteria       including but not limited to gender and ethnicity. We are committed to helping people of diversity find equal opportunities.</p>
                                    {/* <p>We are big on Diversity. We truly believe the U.S. is a land of opportunities and equality, and are committed to helping people of diversity find equal opportunities.</p> */}
                                </div>
                            </Col>

                        </Row>
                        <Row>
                            <Col sm="12" md={{ size: 2, offset: 1 }}>
                                <div className="about_svg">
                                    <img src={Img3} color="#5d6d83" alt="img" />
                                </div>
                            </Col>
                            <Col md="8">
                                <div className="about_text">
                                    <p>We’ve placed people at Startups  positions that remain unfilled for months! <strong> We’ve beaten out shiny large tech companies</strong> in the Valley to convince candidates to work for smaller and more promising startups.</p>
                                    {/* <p>We’ve placed amazing talent at large financial services firms—positions that remained unfilled for months! We’ve beaten out shiny large tech companies in the Valley to convince candidates to work for smaller—and more promising—start ups.</p> */}
                                </div>
                            </Col>
                        </Row>
                        <br/><br/>
                        <Row>
                            <Col md="6">
                                <div className="video_data">
                                    <div>
                                        <img src={Video} alt="img" style={{ height: "263px" }} />
                                    </div>
                                    <p>Trident enters Inc.'s 2020 List of 5000 Fastest Growing Private Companies in America</p>
                                    <p>Ranked 3720,<a target="blank" href="https://www.inc.com/profile/trident-consulting?utm_source=sendinblue&utm_campaign=Trident_PostInc_Award_Email&utm_medium=email">Trident enters this elite list </a> with a 98% growth rate in the past 3 years. Equally important though is the gritty 15% growth recorded in this tough Quarter.</p>
                                    <h5> <a target="blank" href="https://www.inc.com/inc5000/2020?utm_source=sendinblue&utm_campaign=Trident_PostInc_Award_Email&utm_medium=email">Click here for the full list of Inc.'s 2020 awardees...</a></h5>
                                </div>
                            </Col>
                            <Col sm="6">
                                <div className="video_data1">
                                    <div>
                                        <img src={Fast100} alt="img" />
                                    </div>
                                    <h5><a href="https://www.bizjournals.com/sanfrancisco/news/2020/10/29/100-fastest-growing-companies-bay-area-2020.html#g/473052/57" target="blank" >San Francisco Bay Area's fastest-growing private companies in 2020 - San Francisco Business Times</a></h5>
                                    <p>Want to know which Bay Area companies have been growing exponentially over the past few years? Check out our Fast 100 List.</p>
                                    <a href="https://www.linkedin.com/feed/" target="blank">
                                        <FaLinkedin size={20} />
                                    </a>
                                    <br />
                                    <br />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col Col sm="12" md={{ size: 6, offset: 3 }}>
                                <div className="youtube_video">
                                    <YouTube
                                        videoId="42mqns8P510"
                                        onPlay={true}
                                        style={{width:"600px"}}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Zoom>
            </div>

        </section>

    )
}
const StyledBackgroundSection = styled(AboutSection)`
width: 100%;
background-position: bottom center;
background-repeat: repeat-y;
background-size: cover;
margin: 0;
background-color: inherit !important;

`

export default StyledBackgroundSection
